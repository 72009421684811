.NotificationsCenter {
  cursor: default;
  height: 100%;

  .Text {
    color: #FFFFFF;
  }

  .NotificationsCenter__title {
    padding: 8px 16px 8px 16px;
    border-radius: 10px 10px 0px 0px;
  }

  .NotificationsCenter__notifications {
    width: 100%;
    max-width: 300px;
    max-height: 285px;
    overflow-y: auto;

    .notifications__link {
      width: 100%;
      :hover {
        background: #777;
      }
    }
  }

  .NotificationsCenter__seeAll {
    display: flex;
    border-radius: 0px 0px 10px 10px;
    text-align: right;
    margin-top: auto;
    padding: 4px 8px;
    width: 100%;
  }

  .notification {
    padding: 8px 16px 8px 24px;

    .Text.P {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
    }
  }

  .Spinner {
    width: 30px;
    margin: 5px auto;
    border-bottom: none;
  }

  .Tabs {
    .TabList {
      border-bottom: none;

      .TabList__scroll {
        padding: 0px 16px;
        width: 100%;

        .Tab {
          position: relative;
          width: 100%;
          min-width: unset;
          .Tab__label {
            .Text {
              color: #FFFFFF;
              font-weight: 500;
            }
          }
          &.Tab--active {
            border-bottom: 1px solid #46A1FC;

            .Tab__label {
              .Text {
                color: #46A1FC;
                font-weight: 500;
              }
            }
          }
        }
      }
    }

    .Tabs__activeTabsChildren {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 300px;
      height: 288px;
      padding: 8px 0px 0px 0px;

      .NotificationsCenter__notifications {
        max-height: 270px;
      }
    }
  }
}