$logoSize: 40px;

.WmButton.HomeButton {
  height: initial;
  padding: var(--space-xxs) 0 var(--space-xxs) var(--space-xs);
  width: 100%;

  .HomeButton__logoName {
    width: 100%;
  }

  &:hover {
    background: transparent;
  }

  .HomeButton__logoContainer {
    height: $logoSize;
    width: $logoSize;

    .logoContainer__logo {
      max-height: $logoSize;
      max-width: $logoSize;
      
      path {
        width: $logoSize;
      }

      &.WingmateLogo {
        width: $logoSize;
        height: $logoSize;
      }
    }
  }

  .Text.H6 {
    color: var(--white);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}