.CampaignSelector {
  .CampaignSelector__campaign {
    background-color: #F5F5F7;
    padding: 10px;
    width: 100%;
    border: 1px solid #E4E4E4;
  }

  img {
    width: 30px;
    height: fit-content;
  }
}