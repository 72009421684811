@import "../../../../constants";

.DashboardStats {
  .DashboardStats__Divider {
    width: 1px;
    height: 20px;
    background: var(--grey-400);
    flex-shrink: 0;
  }

  @media (max-width: $screenLarge) {
    .DashboardStats__Divider {
      height: 44px;
    }
  }

  @media (max-width: $screenSmall) {
    .DashboardStats__Divider {
      display: none;
    }
  }
}