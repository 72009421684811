.ScoreCardChart {
  min-width: 470px;
  width: fit-content;
  height: 240px;
  font-size: small;
  border: 1px solid #EEE;
  position: relative;
  cursor: default;

  .ScoreCardChart__Lines {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .ScoreCardChart__Line {
    width: inherit;
    border: 1px solid #f0f0f0;
    overflow: auto;
  }

  .ScoreCardChart__Columns {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: flex-end;
    width: inherit;
    height: inherit;
    padding: 0px 10px;
    position: relative;
  }
}