.NotificationsButton {
  position: relative;

  .WmButton {
    
    path {
      fill: var(--grey-500);
    }

    .NotificationsButton__semicircle {
      width: 9px;
      height: 5px;
      border-radius: 5px 5px 0 0;
      background-color: #46A1FC;
      transform: rotate(90deg);
      position: absolute;
      left: 34px;
      top: 12px;
    }
    
    .NotificationsButton__dot {
      width: 9px;
      height: 9px;
      border-radius: 5px;
      position: absolute;
      right: 16px;
      top: 10px;

      &.blue {
        background-color: var(--sky-500);
      }

      &.red {
        background-color: var(--prime-red-500);
      }
    }
  }

  .Flyout__popper {
    max-width: 300px;
    margin-top: 20px;
    border-radius: 10px;
    width: 300px;
    height: 350px;
    background: #555;
  }
}