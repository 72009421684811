@import "constants";
@import "assets/lightmode";
@import "assets/darkmode";

:root {
  // Spacing
  --space-xxs: 4px;
  --space-xs: 8px;
  --space-s: 16px;
  --space-m: 24px;
  --space-l: 32px;
  --space-xl: 48px;
  --space-xxl: 64px;
  --space-xxxl: 96px;

  /*** PRIMITIVE PALETTE ***/

  // Alpha colors
  --black-alpha05: rgba(0, 0, 0, 0.05);
  --black-alpha10: rgba(0, 0, 0, 0.1);
  --black-alpha20: rgba(0, 0, 0, 0.2);
  --black-alpha60: rgba(0, 0, 0, 0.6);
  --white-alpha05: #fff;
  --white-alpha10: #fff;
  --white-alpha20: #fff;

  // Primary colors
  --prime-red-100: #ffdfe3;
  --prime-red-200: #ffbfc8;
  --prime-red-300: #ff9fac;
  --prime-red-400: #fe7e90;
  --prime-red-500: #fe3e59;
  --prime-red-600: #e32641;
  --prime-red-700: #ce122c;
  --prime-red-800: #a31a2d;
  --prime-red-900: #330c12;

  --prime-blue-100: #e9efff;
  --prime-blue-200: #d6e1ff;
  --prime-blue-300: #84a6ff;
  --prime-blue-400: #5a88ff;
  --prime-blue-500: #3e74fe;
  --prime-blue-600: #245ae3;
  --prime-blue-700: #1247ce;
  --prime-blue-800: #1a3ba6;
  --prime-blue-900: #031d37;

  // Neutral colors
  --grey-100: #f5f5f5;
  --grey-200: #f0f0f0;
  --grey-300: #dddddd;
  --grey-400: #bbbbbb;
  --grey-500: #888888;
  --grey-600: #666666;
  --grey-700: #444444;
  --grey-800: #2c2c2c;
  --grey-900: #141414;

  --steel-100: #e6ebf3;
  --steel-200: #cad3e1;
  --steel-300: #a8b4c2;
  --steel-400: #8795a3;
  --steel-500: #657684;
  --steel-600: #435765;
  --steel-700: #223846;
  --steel-800: #102937;
  --steel-900: #001927;

  --white: #ffffff;
  --black: #000000;

  // Secondary colors
  --lime-100: #f0fcde;
  --lime-200: #defabe;
  --lime-300: #c3f199;
  --lime-400: #a6e37c;
  --lime-500: #7fd153;
  --lime-600: #5eb33c;
  --lime-700: #429629;
  --lime-800: #29791a;
  --lime-900: #18640f;

  --tomato-100: #fde9dd;
  --tomato-200: #fccfbc;
  --tomato-300: #f8ac99;
  --tomato-400: #f18c7e;
  --tomato-500: #e85a55;
  --tomato-600: #c73e45;
  --tomato-700: #a72a3b;
  --tomato-800: #861b32;
  --tomato-900: #6f102d;

  --orange-100: #ffecdd;
  --orange-200: #ffd9ba;
  --orange-300: #ffc698;
  --orange-400: #ffa052;
  --orange-500: #ff8d30;
  --orange-600: #e77f2a;
  --orange-700: #c4671c;
  --orange-800: #93511a;
  --orange-900: #6c370d;

  --sky-100: #daf3fe;
  --sky-200: #b5e4fe;
  --sky-300: #8fd2fe;
  --sky-400: #74bffd;
  --sky-500: #46a1fc;
  --sky-600: #337dd8;
  --sky-700: #235db5;
  --sky-800: #164192;
  --sky-900: #0d2d78;

  --cyan-100: #ccfffc;
  --cyan-200: #99fcff;
  --cyan-300: #66f0ff;
  --cyan-400: #3fdeff;
  --cyan-500: #00c2ff;
  --cyan-600: #0097db;
  --cyan-700: #0071b7;
  --cyan-800: #005093;
  --cyan-900: #003a7a;

  --yellow-100: #fff9cc;
  --yellow-200: #fff199;
  --yellow-300: #ffe866;
  --yellow-400: #ffde3f;
  --yellow-500: #ffcf00;
  --yellow-600: #dbad00;
  --yellow-700: #b78d00;
  --yellow-800: #936f00;
  --yellow-900: #7a5900;

  --pink-100: #ffe9f3;
  --pink-200: #ffbcda;
  --pink-300: #ff90c1;
  --pink-400: #ff64a8;
  --pink-500: #ff2182;
  --pink-600: #e82c7e;
  --pink-700: #c41c66;
  --pink-800: #931a4f;
  --pink-900: #6d0d37;

  --violet-100: #f6e3ff;
  --violet-200: #e9baff;
  --violet-300: #db8eff;
  --violet-400: #cd66ff;
  --violet-500: #c03dff;
  --violet-600: #aa2ce8;
  --violet-700: #8b1cc2;
  --violet-800: #6c1a93;
  --violet-900: #4f0d6d;

  --slate-100: #e6ecef;
  --slate-200: #cdd9de;
  --slate-300: #b4c6ce;
  --slate-400: #9bb3bd;
  --slate-500: #6a8d9c;
  --slate-600: #517a8c;
  --slate-700: #38677b;
  --slate-800: #1f546b;
  --slate-900: #06415a;

  // Gradient colors
  --sunrise-100: linear-gradient(281deg, var(--pink-200), var(--orange-100));
  --sunrise-200: linear-gradient(281deg, var(--pink-300), var(--orange-200));
  --sunrise-400: linear-gradient(281deg, var(--pink-400), var(--orange-300));
  --sunrise-500: linear-gradient(281deg, var(--pink-500), var(--orange-400));
  --sunrise-600: linear-gradient(281deg, var(--pink-600), var(--orange-500));
  --sunrise-800: linear-gradient(281deg, var(--pink-800), var(--orange-700));

  --azure-100: linear-gradient(103deg, var(--cyan-100), var(--prime-blue-200));
  --azure-200: linear-gradient(103deg, var(--cyan-200), var(--prime-blue-300));
  --azure-400: linear-gradient(103deg, var(--cyan-400), var(--prime-blue-500));
  --azure-500: linear-gradient(103deg, var(--cyan-500), var(--prime-blue-600));
  --azure-600: linear-gradient(103deg, var(--cyan-600), var(--prime-blue-700));
  --azure-800: linear-gradient(103deg, var(--cyan-800), var(--prime-blue-900));

  --peach-100: linear-gradient(282deg, var(--orange-100), var(--prime-red-100));
  --peach-200: linear-gradient(282deg, var(--orange-100), var(--prime-red-200));
  --peach-400: linear-gradient(282deg, var(--orange-200), var(--prime-red-300));
  --peach-500: linear-gradient(282deg, var(--orange-300), var(--prime-red-400));
  --peach-600: linear-gradient(282deg, var(--orange-400), var(--prime-red-500));
  --peach-800: linear-gradient(282deg, var(--orange-600), var(--prime-red-700));

  --blueberry-100: linear-gradient(
    92deg,
    var(--prime-blue-100),
    var(--violet-200)
  );
  --blueberry-200: linear-gradient(
    92deg,
    var(--prime-blue-200),
    var(--violet-300)
  );
  --blueberry-400: linear-gradient(
    92deg,
    var(--prime-blue-300),
    var(--violet-400)
  );
  --blueberry-500: linear-gradient(
    92deg,
    var(--prime-blue-400),
    var(--violet-500)
  );
  --blueberry-600: linear-gradient(
    92deg,
    var(--prime-blue-500),
    var(--violet-600)
  );
  --blueberry-800: linear-gradient(
    92deg,
    var(--prime-blue-500),
    var(--violet-800)
  );

  --grass-100: linear-gradient(95deg, var(--lime-100), var(--lime-300));
  --grass-200: linear-gradient(95deg, var(--lime-200), var(--lime-400));
  --grass-400: linear-gradient(95deg, var(--lime-300), var(--lime-500));
  --grass-500: linear-gradient(95deg, var(--lime-400), var(--lime-600));
  --grass-600: linear-gradient(95deg, var(--lime-500), var(--lime-700));
  --grass-800: linear-gradient(95deg, var(--lime-700), var(--lime-900));

  --abyss-100: linear-gradient(95deg, var(--slate-200), var(--steel-100));
  --abyss-200: linear-gradient(95deg, var(--slate-300), var(--steel-200));
  --abyss-400: linear-gradient(95deg, var(--slate-500), var(--steel-400));
  --abyss-500: linear-gradient(95deg, var(--slate-600), var(--steel-500));
  --abyss-600: linear-gradient(95deg, var(--slate-700), var(--steel-600));
  --abyss-800: linear-gradient(95deg, var(--slate-900), var(--steel-900));
}

html {
  height: 100vh;

  @media print {
    .productfruits--container {
      display: none;
    }
  }
}

#appLayer {
  #mainLayer {
    width: 100%;
    height: 100vh;
    overflow: hidden;

    #pageLayer {
      display: flex;
      flex-direction: column;
      position: relative;
      height: calc(100% - $topNavigationHeight);
      z-index: 1;

      .AdminRoute,
      .AnalyticsRoute,
      .BaseRoute {
        flex: 1;
        overflow: auto;
      }
    }
  }

  #publicLayer {
    height: 100vh;
    overflow: auto;
  }
}

a,
.Link {
  color: var(--prime-red-500);
  text-decoration: none;
}

.flex {
  display: flex;
}

.flex-align {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: baseline;
  width: 100%;
}

.flex-center-space-between {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

label {
  font-family: Roboto, Helvetica, Arial;
  font-style: normal;
  color: #2c2c2c;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  display: block;
}

.Text {
  &.PageTitle {
    color: #435765;

    &.H3 {
      padding-left: 16px;
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
}

h2,
h4,
p {
  margin-top: 0px;
  margin-bottom: 0px;
}

h4 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: normal;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Roboto, Helvetica, Arial;
}

.wingmate-gradient {
  background: rgba($gradientStart, 1);
  background: -moz-linear-gradient(
    -45deg,
    rgba($gradientStart, 1) 0%,
    rgba($gradientEnd, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right bottom,
    color-stop(0%, rgba($gradientStart, 1)),
    color-stop(100%, rgba($gradientEnd, 1))
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba($gradientStart, 1) 0%,
    rgba($gradientEnd, 1) 100%
  );
  background: -o-linear-gradient(
    -45deg,
    rgba($gradientStart, 1) 0%,
    rgba($gradientEnd, 1) 100%
  );
  background: -ms-linear-gradient(
    -45deg,
    rgba($gradientStart, 1) 0%,
    rgba($gradientEnd, 1) 100%
  );
  background: linear-gradient(
    135deg,
    rgba($gradientStart, 1) 0%,
    rgba($gradientEnd, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradientStart', endColorstr='$gradientEnd', GradientType=1 );
}

.MainColumn {
  max-width: 850px;
  gap: 24px;
}

.SideColumn {
  max-width: 410px;
}

.MergeModalPage {
  height: 580px;
}

.ReminderFormModal {
  width: 100%;
  max-width: 800px;

  &.modal__content {
    top: 30px;
    padding: 5px;
    @media (max-width: 800px) {
      width: 98%;
    }

    @media (max-height: 768px) {
      overflow-y: auto;
      max-height: 90%;
      display: block;
    }
  }
}

.react-toggle {
  &.FilterToggle {
    border: none;
    border-radius: 0px;
    display: flex;
    align-items: center;
    background-color: transparent;
    box-shadow: none;
    padding: 4px 8px;
    height: 40px;

    &.on {
      cursor: default;
      background: #ffe1e1;
      .Text {
        color: var(--prime-red-500);
        font-weight: 600;
      }
    }
  }
}
