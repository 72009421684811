.ConfirmDuplicatesModal {
  width: 24vw;
  max-width: 464px;
  min-width: 300px;
  padding: 40px;

  .ConfirmDuplicatesModal__text {
    text-align: center;
  }

  .Button {
    width: 125px;
  }
}