.OauthProcessing {
  width: 40vw;
  min-width: 455px;
  max-width: 500px;
  margin: auto;
  margin-top: 100px;
  padding: 64px 32px;
  border: 1px solid #CFD6E3;
  min-height: 295px;

  .OauthProcessing__title {
    min-height: 28px;
  }

  .OauthProcessing__success path {
    fill: #15BB8F;
  }

  .OauthProcessing__failed path, circle {
    stroke: #C44536;
  }

  .Text {
    text-align: center;
  }

  .Spinner {
    height: 50px;
    margin: 15px;
  }
}