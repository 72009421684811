.ConfirmText {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: -1px 0px;

  svg path {
    fill: #C4C4C4;
  }

  .textField .textField__input {
    max-height: 19px;
    min-height: 19px;
    width: 160px;
    padding: 12px 3px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #2C2C2C;
    background-color: #E8F4F8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Text.P {
    min-height: 0px;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    color: #2C2C2C;
    padding: 0px 3px;
    border-radius: 5px;
    cursor: text;

    &:hover {
      background-color: #EEEEEE;
    }
  }
}