$columnWidth: 60px;

.ScoreCardChart__Progress {
  width: $columnWidth;
  background: rgb(91,86,215);
  background: linear-gradient(0deg, rgba(91,86,215,0.8) 15%, rgba(197,68,252,0.8) 85%);
}

.ScoreCardChart__Target {
  width: $columnWidth;
}

.ScoreCardChart__ColumnCount {
  position: absolute;
  text-align: center;
  width: $columnWidth;
  bottom: -20px;
}

.ScoreCardChart__Column {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: rgba(85, 85, 85, 0.1);
  border-left: 3px solid #7B61FF;
  border-right: 3px solid #7B61FF;

  &.Column--complete {
    border-left: 0px;
    border-right: 0px;

    .ScoreCardChart__Target {
      border-top: 1px solid white;
    }

    .ScoreCardChart__Progress {
      background: #58FF49;
      background: linear-gradient(0deg, #37D95B 15%, #58FF49 85%);
    }

    .ScoreCardChart__ColumnCount {
      filter: none;
    }
  }
}

.ScoreCardChart__Icon {
  position: absolute;
  top: -10px;

  svg {
    position: relative;
    margin-left: 17px;
    z-index: 1;
  }

  .Column__TargetLine {
    position: relative;
    top: 13px;
    z-index: 0;
    border-top: 2px solid white;
    width: $columnWidth;
  }
}

.ScoreCardChart__Tick {
  border-left: 1px solid black;
  height: 6px;
  position: absolute;
  top: -20px;
  left: calc($columnWidth / 2);
}

.ScoreCardChart__ColumnTitle {
  position: absolute;
  flex-direction: column;
  justify-content: center;
  bottom: -50px;
  width: $columnWidth + 4;
  height: 32px;

  .ScoreCardChart__TitleText {
    text-align: center;
    margin: 5px -5px 0px -5px;
    overflow-y: hidden;

    .Text {
      word-break: break-word;
    }
  }
}