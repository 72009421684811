$gradientStart: #ff8d30;
$gradientEnd:   #ff0873;
$topNavigationHeight: 72px;
$sideNavigationWidth: 72px;
$sideNavigationExpandedWidth: 264px;
$screenSmall: 599px;
$screenMedium: 899px;
$screenLarge: 1279px;
$leadFlyoutHeight: 476px;
$leadFlyoutWidth: 330px;
$leadDetailSideColumnWidth: 340px;
$leadDetailMainColumnWidth: 796px;