:root {
  /** LIGHT PALETTE **/

  /* background/banners */
  --background-banners-primary01: var(--orange-300);
  --background-banners-primary02: var(--prime-red-400);
  --background-banners-secondary01: var(--slate-900);
  --background-banners-secondary02: var(--steel-900);

  /* background/bubble */
  --background-bubble-primary: var(--orange-100);
  --background-bubble-secondary: var(--orange-200);

  /* background/button */
  --background-button-disabled: var(--grey-300);
  --background-button-hover-grey: var(--black-alpha05);
  --background-button-icon: var(--white);
  --background-button-pressed-grey: var(--black-alpha10);
  --background-button-pressed-secondary01: var(--slate-900);
  --background-button-pressed-secondary02: var(--steel-900);

  /* background/cards */
  --background-cards-card: var(--white);
  --background-cards-select: var(--grey-200);

  /* background/general */
  --background-general-primary: var(--white);
  --background-general-steel: var(--steel-100);

  /* background/general/success */
  --background-general-success-heavy: var(--lime-600);
  --background-general-success-light: var(--lime-100);
  --background-general-success-medium: var(--lime-400);
  --background-general-success-translucent: rgba(166, 227, 124, 0.6);

  /* background/general/error */
  --background-general-error-heavy: var(--tomato-600);
  --background-general-error-light: var(--tomato-100);
  --background-general-error-medium: var(--tomato-400);
  --background-general-error-translucent: rgba(254, 126, 144, 0.6);

  /* background/general/info */
  --background-general-info-heavy: var(--sky-600);
  --background-general-info-light: var(--sky-100);
  --background-general-info-medium: var(--sky-400);
  --background-general-info-translucent: rgba(116, 191, 253, 0.6);

  /* background/general/warning */
  --background-general-warning-heavy: var(--yellow-600);
  --background-general-warning-light: var(--yellow-100);
  --background-general-warning-medium: var(--yellow-400);
  --background-general-warning-translucent: rgba(255, 222, 63, 0.6);

  /* background/menu */
  --background-menu-active-accent: var(--prime-blue-500);
  --background-menu-disabled: var(--grey-300);
  --background-menu-hover-grey: var(--black-alpha05);
  --background-menu-pressed-grey: var(--black-alpha10);
  --background-menu-selected-accent: var(--prime-blue-100);
  
  /* background/overlay */
  --background-overlay: var(--grey-200);

  /* background/scroll */
  --background-scroll-active: var(--black-alpha20);
  --background-scroll-inactive: var(--black-alpha10);

  /* background/switch */
  --background-switch: var(--prime-blue-600);

  /* background/tag */
  --background-tag-lime: var(--lime-100);
  --background-tag-orange: var(--orange-100);
  --background-tag-pink: var(--pink-100);
  --background-tag-primary: var(--grey-200);
  --background-tag-sky: var(--sky-100);
  --background-tag-tomato: var(--tomato-100);

  /* background/textfield */
  --background-textfield-input: transparent;

  /* background/tooltip */
  --background-tooltip-primary: var(--steel-900);

  /* border/general */
  --border-general-error: var(--tomato-500);
  --border-general-separator: var(--grey-400);

  /* border/general/success */
  --border-general-success-heavy: var(--lime-700);
  --border-general-success-light: var(--lime-200);
  --border-general-success-medium: var(--lime-500);

  /* border/general/error */
  --border-general-error-heavy: var(--tomato-700);
  --border-general-error-light: var(--tomato-200);
  --border-general-error-medium: var(--tomato-400);

  /* border/general/info */
  --border-general-info-heavy: var(--sky-700);
  --border-general-info-light: var(--sky-200);
  --border-general-info-medium: var(--sky-400);

  /* border/general/warning */
  --border-general-warning-heavy: var(--orange-700);
  --border-general-warning-light: var(--orange-100);
  --border-general-warning-medium: var(--orange-500);

  /* border/menu */
  --border-menu-active-accent: var(--prime-blue-600);
  --border-menu-primary: var(--grey-400);
  --border-menu-secondary: var(--grey-300);

  /* border/tag */
  --border-tag-lime: var(--lime-400);
  --border-tag-orange: var(--orange-400);
  --border-tag-pink: var(--pink-300);
  --border-tag-primary: var(--grey-400);
  --border-tag-sky: var(--sky-400);
  --border-tag-tomato: var(--tomato-400);

  /* border/textfield */
  --border-textfield-hover: var(--prime-blue-300);
  --border-textfield-pressed: var(--prime-blue-500);
  --border-textfield-primary: var(--grey-400);
  --border-textfield-secondary: var(--grey-300);

  /* content/button */
  --content-button-text-button-blue: var(--prime-blue-600);
  --content-button-red: var(--prime-red-600);

  /* content/general */
  --content-general-accent: var(--prime-blue-600);
  --content-general-brand: var(--prime-red-600);
  --content-general-disabled: var(--grey-400);
  --content-general-primary: var(--grey-800);
  --content-general-primary-inverse: var(--white);
  --content-general-secondary: var(--grey-600);
  --content-general-tertiary: var(--grey-500);

  /* content/general/success */
  --content-general-success-heavy: var(--lime-600);
  --content-general-success-light: var(--lime-100);
  --content-general-success-medium: var(--lime-400);

  /* content/general/error */
  --content-general-error-heavy: var(--tomato-600);
  --content-general-error-light: var(--tomato-100);
  --content-general-error-medium: var(--tomato-400);

  /* content/general/info */
  --content-general-info-heavy: var(--sky-600);
  --content-general-info-light: var(--sky-100);
  --content-general-info-medium: var(--sky-400);

  /* content/general/warning */
  --content-general-warning-heavy: var(--orange-600);
  --content-general-warning-light: var(--orange-400);
  --content-general-warning-tertiary: var(--orange-100);

  /* content/tag */
  --content-tag-lime: var(--lime-700);
  --content-tag-orange: var(--orange-700);
  --content-tag-pink: var(--pink-700);
  --content-tag-primary: var(--grey-700);
  --content-tag-sky: var(--sky-700);
  --content-tag-tomato: var(--tomato-600);

  /* content/textfield */
  --content-textfield-disabled: var(--grey-400);
  --content-textfield-placeholder: var(--grey-500);
  --content-textfield-primary: var(--grey-800);
  --content-textfield-primary-inverse: var(--white);
  --content-textfield-secondary: var(--grey-600);

  /* overlay */
  --overlay: var(--black-alpha60);

  /* shadow */
  --shadow-dark: var(--black);
  --shadow-banner-primary: rgba(254, 126, 144, 0.4);
}