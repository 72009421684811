.Username {
  cursor: default;
  
  .Username__name {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .Username__photo {
    position: relative;
  }
}
