.SearchField {
  .SearchField__SearchIcon {
    flex-shrink: 0;

    path {
      fill: var(--content-textfield-placeholder);
    }
  }

  .Mui-focused .SearchField__SearchIcon path {
    fill: var(--content-general-accent);
  }

  .WmButton.small {
    margin-right: calc(-1 * var(--space-xs));
    padding: var(--space-xxs) 9px;
    border-radius: 50%;

    path {
      fill: var(--content-textfield-placeholder);
    }
  }
}
