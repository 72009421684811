.Flyout__popper {
  z-index: 500;

  .Flyout__arrow,
  .Flyout__arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  .Flyout__arrow {
    visibility: hidden;
  }

  .Flyout__arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
}