.GlobalSearch__Button {
  padding-left: 18px;
  padding-right: 18px;

  svg path {
    fill: var(--content-general-tertiary);
  }
}

.GlobalSearch {
  flex: 1;
  max-width: 716px;

  .GlobalSearch__TextField {
    .MuiOutlinedInput-notchedOutline {
      border: none;
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.05);
    }

    .SearchField__SearchIcon {
      width: 20px;
      height: 20px;
    }
  }
}