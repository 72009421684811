@import "../../../../constants";

.GlobalSearchDropdown {
  position: absolute;
  inset: 72px 0 0 0;
  background: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(2px);

  .GlobalSearchDropdown__Container {
    display: flex;
    height: fit-content;
    min-height: 258px;
    max-height: 60%;
    width: 60%;
    max-width: 965px;
    padding: var(--space-m) var(--space-l) var(--space-l) var(--space-l);
    background: var(--white);
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.08), 0px 4px 4px 0px rgba(0, 0, 0, 0.15);

    .GlobalSearchDropdown__Content {
      flex: 1;
      width: 60%;

      .Content-noResult .WmSpinner {
        margin: var(--space-m);
      }
      
      .Content--grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
        gap: var(--space-m) var(--space-s);
        overflow-y: auto;
        max-height: 290px;
      }
    }
  }

  &.sideNavExpanded {
    inset: 72px 0 0 $sideNavigationExpandedWidth;

    .GlobalSearchDropdown__Container {
      width: 90%;
    }
  }

  @media (max-width: $screenMedium) {
    .GlobalSearchDropdown__Container {
      width: 100%;
      margin-left: var(--space-s);
      margin-right: var(--space-s);
    }
  }
}