.ConfirmButtons {
  gap: 4px;

  div {
    display: flex;
  }

  .ConfirmButtons__cancel svg path, circle {
    stroke: #D3D3D3;
  }

  .ConfirmButtons__confirm svg path {
    fill: #D3D3D3;
  }

  .ConfirmButtons__confirm {
    &:hover {
      svg path {
        fill: #15BB8F;
      }
    }
  }

  .ConfirmButtons__cancel {
    &:hover {
      svg path, circle {
        stroke: #C20000;
      }
    }
  }
}