.ErrorPage {
  padding-top: 96px;

  svg {
    margin-top: 24px;
    margin-bottom: 32px;
  }

  a > .Text {
    color: var(--prime-red-500);
  }
}