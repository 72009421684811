.DashboardDateFilter {
  min-width: 200px;
  height: 40px;

  svg path {
    fill: #BBBBBB;
  }

}

.DashboardDateFilter__Button {

  .Text {
    color: white;
  }
}