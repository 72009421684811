.Section {
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 8px;

  &.SideSection {
    margin-top: 8px;
    padding: 16px;
  }
}