.OauthSetup {
  height: 100vh;

  .Section {
    min-width: 455px;
    max-width: 500px;
    min-height: 490px;
    padding: 80px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .OauthSetup__userMessage {
    min-height: 60px;
    color: #C44536;
  }

  .SelectFieldContainer {
    width: 100%;
  }

  .Button {
    width: 100%;
  }

  .FlexCol {
    width: 100%;
  }
  
  .Spinner {
    margin: auto;
  }
}