.NavigationItem {
  .NavigationItem__Link {
    flex: 1;
    display: flex;
    align-items: flex-start;
    padding: var(--space-s);

    .Text {
      color: var(--steel-400);
    }

    .Link__Icon {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  
    &.active, &:hover {
      .Text {
        color: var(--white);
      }
    }
  }

  &.WmAccordion.outlined {
    background-color: transparent;
    border: none;

    .MuiAccordionSummary-root.WmAccordion__Summary {
      border-radius: 4px;
      padding: 0 var(--space-s) 0 0;

      .Link__Chevron {
        &.active {
          path {
            fill: var(--white);
          }
        }
      }

      &:hover {
        background-color: var(--steel-700);
        color: var(--white);

        .Text, .Link__Chevron path {
          color: var(--white);
          fill: var(--white);
        }
      }

      .MuiAccordionSummary-content {
        margin: 0px;
      }
    }
  }

  .MuiAccordionDetails-root {
    padding: 0px;

    .NavigationItem__Children {
      .NavigationItem__Link {
        padding: var(--space-xs) var(--space-m) var(--space-xs) var(--space-xxl);
      }
    }
  }
}