.GlobalSearchResult {
  border-radius: 4px;
  padding: var(--space-xxs);
  max-width: 295px;
  cursor: pointer;

  &:hover {
    background-color: var(--background-cards-select);
  }

  .GlobalSearchResult__Thumbnail {
    border-radius: 4px;
    min-width: 80px;
    object-fit: cover;
  }

  .GlobalSearchResult__Details {
    min-width: 0;

    .Text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .match {
        color: var(--prime-red-500);
      }
    }
  
    .Details__Address {
      .Text {
        color: var(--content-general-secondary);
      }
  
      svg {
        height: 23px;
        width: 14px;
        flex-shrink: 0;
      }
    }
  }
}