.Banner {
  display: flex;
  width: 18vw;
  min-width: 250px;
  background-color: white;
  color: black;
  z-index: 900;
  border-radius: 4px;
  -webkit-box-shadow: 7px 7px 12px -9px #777777;
  -moz-box-shadow: 7px 7px 12px -9px #777777;
  box-shadow: 7px 7px 12px -9px #777777;

  .Banner__colorBar {
    flex: 1;
    min-width: 8px;
    border-radius: 4px 0px 0px 4px;
    height: 100%;

    &.green {
      background-color: #40AA6D;
    }

    &.red {
      background-color: red;
    }
  }

  .banner__content {
    flex: 30;
    gap: 5px;
    border-radius: 0px 4px 4px 0px;

    .content__header {
      width: 100%;
      padding: 5px 10px 5px 5px;
      height: fit-content;

      .header__title {
        max-width: 15vw;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .header__closeButton {
        cursor: pointer;
      }
    }

    .content__body {
      padding: 5px;
      overflow: auto;
      max-height: 60px;
    }

    .content__actions {
      width: 100%;
    }
  }
}