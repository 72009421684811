.CurrentScoreCard {
  position: relative;

  svg {
    width: 24px;
    height: 24px;
  }

  .CurrentScoreCard__scoreCard {
    position: absolute;
    top: 60px;
    right: -180px;
    display: flex;
    flex-direction: column;
    padding: var(--space-s);
    gap: var(--space-s);
    width: 500px;
    height: 420px;
    cursor: default;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

    .scoreCard__chartContainer {
      width: 100%;
      height: 100%;
      overflow-x: auto;
    }
  }

  .CurrentScoreCard__topHeader {
    display: flex;
    padding-left: var(--space-s);

    .dateRange {
      text-align: right;
      flex: 1;
    }
  }
}