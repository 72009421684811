.Button.AddLeadSlideoutButton {
  right: 90px;
  bottom: 90px;
  position: fixed;
  color: white;
  background: linear-gradient(145deg, rgba(226,124,41,1) 0%, rgba(255,8,115,1) 87%, rgba(228,6,102,1) 100%);
  border: 5px solid white;
  -webkit-box-shadow: 1px 2px 7px -1px #777777;
  -moz-box-shadow: 1px 2px 7px -1px #777777;
  box-shadow: 1px 2px 7px -1px #777777;
  padding: 13px 13px;
  z-index: 10;

  @media print {
    display: none;
  }

  &.round {
    padding: 15px 15px;
  }

  svg path {
    fill: white;
  }
}