.AddLeadForm {

  .AddLeadForm__FormContainer {
    width: 100%;
  }

  .AddLeadForm__duplicateMessage {
    color: #A28623;
    text-align: center;
    visibility: hidden;
  }

  .CreateForm {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 200px);
    padding: 0px 0px;
    gap: 0px;
  }

  .CreateForm .CreateForm__title {
    width: 100%;
    padding: 16px;
  }

  .CreateForm .CreateForm__fields {
    flex: 9;
    padding-bottom: 8px;
  }

  .upload__container {
    gap: 8px;
  }

  .Button {
    width: 80px;
    height: 40px;
    padding: 8px 16px;
  }

  .FlexCol {
    width: 100%;
  }

  .CreateForm__buttons {
    border-top: 1px solid #d4d4d4;
    flex: 1;
  }
}