@import "../../../../constants";

.NavigationPin {
  position: absolute;
  right: -7px;
  top: 122px;
  cursor: pointer;

  &.pinned {
    svg > path, rect {
      fill: var(--steel-100);
    }
  }

  &.unpinned {
    #Subtract, #circle > rect {
      fill: var(--steel-100);
    }
  }

  @media (max-width: $screenMedium) {
    display: none;
  }
}