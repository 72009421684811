.GlobalSearchCampaignToggle > .ant-flex {
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 12px;
  
  .WmButton {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 0 0 auto;
  }

  .WmSpinner path {
    fill: var(--prime-red-500);
  }
}