@import "../../../../constants";

.CurrentSegment {
  .Text.P {
    max-width: 300px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  @media (max-width: $screenLarge) {
    flex-direction: column;
  }

  @media (max-width: $screenSmall) {
    display: none;
  }
}