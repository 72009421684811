.ApprovalItem {
  padding: 8px 16px 8px 24px;

  .Button {
    width: 100%;
    display: block;
    padding: 0;
    box-shadow: none;

    &:hover {
      color: white;
      text-decoration: none;
    }

    .Text {
      color: #FFF;
    }

    .link__info {
      .info__item {
        width: 100%;
  
        .item__date {
          white-space: nowrap;
        }
      }
    }
  }

  .ApprovalItem__link {
    flex: 1;
  }

  &:hover {
    background: #777;
  }

  .ApprovalItem__actionButtons {
    padding: 0px;

    svg {
      cursor: pointer;
    }
  }
}