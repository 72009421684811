.CreateForm {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  gap: 15px;
  padding: 8px 24px;
  
  .CreateForm__title {
    width: 100%;
    padding: 0px 0px 8px 0px;
    text-align: center;
    border-bottom: 1px solid #d4d4d4;
    font-weight: 500;
  }

  .CreateForm__fields {
    flex-grow: 1;
    width: inherit;
    overflow-y: scroll;

    .fields__field {
      width: 100%;
      max-width: 500px;
      display: block;

      .SelectFieldContainer, .MultiSelectContainer {
        width: 100%;
      }

      .ant-picker {
        width: 100%;
      }

      .textAreaField .textAreaField__input {
        max-width: 100%;
        min-width: 100%;
        max-height: 80px;
      }
    }
  }

  .CreateForm__buttons {
    width: inherit;
  }
}