@import "../../../constants";

.ContractSelector {

  .SearchDropdown input {
    height: 32px;
    padding-top: var(--space-xxs);
    padding-bottom: var(--space-xxs);
  }

}

.ContractSelector__dropdown {
  max-height: 296px;
  overflow-y: auto;

  .dropdown__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 30px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  @media (max-width: $screenMedium) {
    width: 70%;
  }
}